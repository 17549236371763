// extracted by mini-css-extract-plugin
export var hgwIconArrowDown = "Header-module--hgwIcon-arrow-down--BIyh7";
export var hgwIconArrowUp = "Header-module--hgwIcon-arrow-up--1BCm5";
export var base = "Header-module--base--2bxto";
export var langListItem = "Header-module--langListItem--1Jiq4";
export var isSpMenuOpened = "Header-module--isSpMenuOpened--DcnAn";
export var motionMenuBaseOpen = "Header-module--motionMenuBaseOpen--ylgXZ";
export var motionMenuTextOpen = "Header-module--motionMenuTextOpen--3fSRP";
export var pcNone = "Header-module--pcNone--3H05r";
export var spNone = "Header-module--spNone--2aa6q";
export var spNavTrigger = "Header-module--spNavTrigger--3aBb3";
export var spNavTriggerHitArea = "Header-module--spNavTriggerHitArea--1lX4j";
export var animBarTypeA = "Header-module--animBarTypeA--1duQd";
export var animBarTypeB = "Header-module--animBarTypeB--1iyXu";
export var animBarTypeC = "Header-module--animBarTypeC--2tRMA";
export var animBarTypeD = "Header-module--animBarTypeD--2qWad";