import React, { useCallback, useState } from "react";
import * as css from "./Header.module.scss";
import { Link } from "gatsby";

type HeaderProps = {
  lang: "ja" | "en";
};

const Header: React.FC<HeaderProps> = (props) => {
  const [stateSpMenuOpened, setStateSpMenuOpened] = useState(false);

  const doToggleSpMenu = useCallback(() => {
    setStateSpMenuOpened(!stateSpMenuOpened);
  }, [stateSpMenuOpened, setStateSpMenuOpened]);

  const classIsSpMenuOpened = stateSpMenuOpened ? css.isSpMenuOpened : "";

  return (
    <div className={css.base}>
      <h1>
        {props.lang === "ja" ? (
          <Link to={"/ja"} lang={`en`}>
            LIMINAL SPACE, WORLD WIDE REPORT
          </Link>
        ) : (
          <Link to={"/"}>LIMINAL SPACE, WORLD WIDE REPORT</Link>
        )}
      </h1>

      {/* for pc*/}
      <nav className={classIsSpMenuOpened}>
        <ul>
          <li className={css.pcNone}>
            {props.lang === "ja" ? (
              <Link to={"/ja/"}>トップ</Link>
            ) : (
              <Link to={"/"}>Home</Link>
            )}
          </li>
          <li>
            {props.lang === "ja" ? (
              <Link to={"/ja/about"}>このプロジェクトについて</Link>
            ) : (
              <Link to={"/about"}>About</Link>
            )}
          </li>
          <li>
            <Link to={"/links"}>
              {props.lang === "ja" ? (
                <Link to={"/ja/links"}>リンク集</Link>
              ) : (
                <Link to={"/links"}>Links</Link>
              )}
            </Link>
          </li>
          <li className={css.langListItem}>
            {props.lang === "ja" ? (
              <Link to={"/"}>
                En / <i>Ja</i>
              </Link>
            ) : (
              <Link to={"/ja"}>
                <i>En</i> / Ja
              </Link>
            )}
          </li>
        </ul>
      </nav>

      {/*  for SP */}
      <button
        className={css.spNavTrigger + ` ` + classIsSpMenuOpened}
        onClick={doToggleSpMenu}
      />
      <button
        className={css.spNavTriggerHitArea + ` ` + classIsSpMenuOpened}
        onClick={doToggleSpMenu}
      />
    </div>
  );
};

export default Header;
